import _find from 'lodash/find';
import _get from 'lodash/get';

export default (lang, data) => {
  const nodes = _get(data, 'allMarkdownRemark.nodes', []);
  const inCurrentLang = _find(nodes, (node) => node.frontmatter.lang === lang);

  return inCurrentLang || {
    html: '',
  };
};
